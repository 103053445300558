(function (angular, app) {
    'use strict';

    app.directive('spTooltip', [
        '$compile',
        'Util',
        '$window',
        function ($compile, util, $window) {
            return {
                restrict: 'A',
                scope: {
                    content: '@?spTooltip',
                    isRemovePrevious: '@?spTooltipRemovePrevious',
                    isCheckingOver: '=?spTooltipCheckingOver',
                    alignment: '=?spTooltipAlignment',
                    isGlobal: '=?spIsGlobal',
                    anchorToElement: '=?spAnchorToElement',
                    wrapperClass: '=?spWrapperClass',
                },
                link: function ($scope, $element) {
                    $scope.isRemovePrevious = $scope.isRemovePrevious === 'true';
                    function _bind() {
                        var template = $scope.content && "<div class='tooltip-content'>" + $scope.content + '</div>';
                        if (template) {
                            if ($scope.isRemovePrevious && $element[0]) {
                                var tooltips = $element[0].querySelectorAll('.tooltip-content');
                                if (tooltips.length > 0) {
                                    tooltips[0].remove();
                                }
                            }
                            $element.addClass('tooltip');
                            if ($scope.isGlobal && $scope.content) {
                                $element.on('mouseenter', function () {
                                    document.body.insertAdjacentHTML(
                                        'beforeend',
                                        "<div class='product-tag-tooltip-wrapper'><div class='product-tag-tooltip-content'>" +
                                            $scope.content +
                                            "</div><div class='tooltip-arrow'>&nbsp;</div></div>"
                                    );
                                    var tooltipWrapperElement = document.querySelector('.product-tag-tooltip-wrapper');
                                    if (!tooltipWrapperElement) return;

                                    if($scope.wrapperClass) {
                                        if (tooltipWrapperElement.className.indexOf($scope.wrapperClass) === -1) {
                                            tooltipWrapperElement.className += ' ' + $scope.wrapperClass;
                                        }
                                    }

                                    var removeWrapper = function () {
                                        if (tooltipWrapperElement) {
                                            tooltipWrapperElement.remove();
                                        }
                                    };
                                    var tooltipArrowElement = tooltipWrapperElement.querySelector('.tooltip-arrow');
                                    if (!tooltipArrowElement) {
                                        removeWrapper();
                                        return;
                                    }

                                    var isRTL = document.documentElement.classList.contains('rtl');
                                    var updateTooltipPosition = function () {
                                        var iconElement = $element[0].querySelector('.product-main-tag-icon');
                                        if (!iconElement) {
                                            removeWrapper();
                                            return;
                                        }
                                        var elementPosition = iconElement.getBoundingClientRect();
                                        if (!elementPosition) {
                                            removeWrapper();
                                            return;
                                        }
                                        if (tooltipWrapperElement && tooltipWrapperElement.offsetWidth > 0) {
                                            var iconWidth = iconElement.offsetWidth / 2;
                                            tooltipWrapperElement.style.top =
                                                elementPosition.top + document.documentElement.scrollTop - tooltipWrapperElement.offsetHeight + 'px';
                                            if ($scope.alignment === 'center') {
                                                var isCenter = true;
                                                if ($scope.anchorToElement) {
                                                    var anchoredElement = document.querySelector('.' + $scope.anchorToElement);
                                                    if (!anchoredElement) {
                                                        removeWrapper();
                                                        return;
                                                    }
                                                    if (
                                                        anchoredElement.offsetWidth > tooltipWrapperElement.offsetWidth &&
                                                        tooltipWrapperElement.offsetWidth > 126
                                                    ) {
                                                        var anchoredElementPosition = anchoredElement.getBoundingClientRect();
                                                        if (!anchoredElementPosition) {
                                                            removeWrapper();
                                                            return;
                                                        }
                                                        if (isRTL) {
                                                            tooltipWrapperElement.style.left =
                                                                anchoredElementPosition.left +
                                                                anchoredElement.offsetWidth -
                                                                tooltipWrapperElement.offsetWidth -
                                                                10 +
                                                                'px';
                                                            tooltipArrowElement.style.marginRight = '82px';
                                                            tooltipArrowElement.style.marginLeft = 'auto';
                                                        } else {
                                                            tooltipWrapperElement.style.left = anchoredElementPosition.left + 10 + 'px';
                                                            tooltipArrowElement.style.marginLeft = '92px';
                                                            tooltipArrowElement.style.marginRight = 'auto';
                                                        }
                                                        isCenter = false;
                                                    }
                                                }
                                                if (isCenter) {
                                                    var positionLeft =
                                                        elementPosition.left +
                                                        document.documentElement.scrollLeft -
                                                        tooltipWrapperElement.offsetWidth / 2 +
                                                        iconWidth;
                                                    if (positionLeft + tooltipWrapperElement.offsetWidth > $window.innerWidth) {
                                                        tooltipWrapperElement.style.left =
                                                            $window.innerWidth - tooltipWrapperElement.offsetWidth - 20 + 'px';
                                                    } else {
                                                        tooltipWrapperElement.style.left = (positionLeft < 0 ? 10 : positionLeft) + 'px';
                                                    }
                                                    var tooltipWrapperElementPosition = tooltipWrapperElement.getBoundingClientRect();
                                                    tooltipArrowElement.style.marginLeft =
                                                        elementPosition.left - tooltipWrapperElementPosition.left + iconWidth - 10 + 'px';
                                                    tooltipArrowElement.style.marginRight = 'auto';
                                                }
                                            } else if ($scope.alignment === 'left') {
                                                var isLeft = true;
                                                if ($scope.anchorToElement) {
                                                    var anchoredElement = document.querySelector('.' + $scope.anchorToElement);
                                                    if (!anchoredElement) {
                                                        removeWrapper();
                                                        return;
                                                    }
                                                    var anchoredElementPosition = anchoredElement.getBoundingClientRect();
                                                    if (!anchoredElementPosition) {
                                                        removeWrapper();
                                                        return;
                                                    }
                                                    if (isRTL) {
                                                        tooltipWrapperElement.style.left = anchoredElementPosition.left + 10 + 'px';
                                                        tooltipArrowElement.style.marginLeft = iconWidth - 10 + 'px';
                                                        tooltipArrowElement.style.marginRight = 'auto';
                                                    } else {
                                                        tooltipWrapperElement.style.left =
                                                            anchoredElementPosition.left +
                                                            anchoredElement.offsetWidth -
                                                            tooltipWrapperElement.offsetWidth -
                                                            10 +
                                                            'px';
                                                        tooltipArrowElement.style.marginRight = iconWidth - 10 + 'px';
                                                        tooltipArrowElement.style.marginLeft = 'auto';
                                                    }
                                                    isLeft = false;
                                                }
                                                if (isLeft) {
                                                    var positionLeft = elementPosition.left + document.documentElement.scrollLeft;
                                                    if (isRTL) {
                                                        tooltipWrapperElement.style.left = (positionLeft - 10 < 0 ? 10 : positionLeft - 10) + 'px';
                                                        tooltipArrowElement.style.marginLeft = iconWidth + 'px';
                                                        tooltipArrowElement.style.marginRight = 'auto';
                                                    } else {
                                                        tooltipWrapperElement.style.left =
                                                            (positionLeft - tooltipWrapperElement.offsetWidth + 60 < 0
                                                                ? 0
                                                                : positionLeft - tooltipWrapperElement.offsetWidth + 60) + 'px';
                                                        tooltipArrowElement.style.marginLeft = 'auto';
                                                        tooltipArrowElement.style.marginRight = iconWidth + 'px';
                                                    }
                                                    tooltipWrapperElement.style.right = 'auto';
                                                }
                                            }
                                        }
                                    };
                                    updateTooltipPosition();
                                    var scrollHandler = function () {
                                        updateTooltipPosition();
                                    };
                                    document.addEventListener('scroll', scrollHandler, true);
                                    $element.on('mouseleave', function () {
                                        if (tooltipWrapperElement) {
                                            tooltipWrapperElement.remove();
                                        }
                                        document.removeEventListener('scroll', scrollHandler, true);
                                    });
                                });
                            } else {
                                $element.append(template);
                            }
                            $compile($element.contents())($scope.$parent);
                            if ($scope.isCheckingOver) {
                                $element.on('mouseenter', function () {
                                    var tooltipContentElement = $element[0].querySelector('.tooltip-content');

                                    if (tooltipContentElement) {
                                        var tooltipRect = tooltipContentElement.getBoundingClientRect();
                                        if (tooltipRect.left < 0) {
                                            tooltipContentElement.style.left = '100%';
                                        } else if (tooltipRect.right > $window.innerWidth) {
                                            tooltipContentElement.style.left = '34%';
                                        }
                                        if (tooltipRect.top < 0) {
                                            tooltipContentElement.style.top = '10px';
                                        } else if (tooltipRect.bottom > $window.innerHeight) {
                                            tooltipContentElement.style.top = $window.innerHeight - tooltipRect.height - 10 + 'px';
                                        }
                                    }
                                });
                            }
                        }
                    }

                    util.currentScopeListener($scope, $scope.$watch('content', _bind));
                },
            };
        },
    ]);
})(angular, app);
